<template>
  <div class="productsdetail">
    <div class="productsdetail_mybreadcrumb">
      当前位置： <app-mybreadcrumb />
    </div>

    <div class="productsdetail_content">
      <section class="productsdetail_productInfo">
        <div class="productsdetail_top">
          <div class="productsdetail_img_box">
            <img :src="product_info.gallery" class="productsdetail_img_pic" />
          </div>

          <div class="productsdetail_user">
            <div class="productsdetail_user_name" :title="product_info.name">
              {{ product_info.name }}
            </div>
            <div class="productsdetail_user_com">
              发行机构：{{ product_info.fin_name }}
            </div>
            <div class="productsdetail_user_label">
              <div class="productsdetail_label_item">
                {{ product_info.label_name }}
              </div>
            </div>
          </div>

          <div class="productsdetail_make">
            <div
              class="productsdetail_now"
              @click="towebapply(product_info.id)"
            >
              {{ info_font }}
            </div>
            <div class="productsdetail_make_num" @click="getVailAsk">
              申请次数：{{ product_info.req_num }}次
            </div>
          </div>
        </div>
      </section>

      <section class="productsdetail_mesg">
        <div class="el-col el-col-8">
          <div class="productsdetail_col">
            <div class="productsdetail_font">
              融资额度：
              <span
                >{{ product_info.min_quota }}~{{
                  product_info.max_quota
                }}万</span
              >
            </div>
            <div class="productsdetail_font">
              利率：
              <span
                >{{ product_info.min_rate }}%~{{ product_info.max_rate }}%</span
              >
            </div>
            <div class="productsdetail_font">
              适用地区： <span>{{ product_info.region_name }}</span>
            </div>
          </div>
        </div>

        <div class="el-col el-col-8">
          <div class="productsdetail_font">
            还款期限：
            <span
              >{{ product_info.min_credit }}~{{
                product_info.max_credit
              }}个月</span
            >
          </div>
          <div class="productsdetail_font">
            所属机构： <span>{{ product_info.fin_name }}</span>
          </div>
          <div class="productsdetail_font">
            担保方式： <span>{{ product_info.guar_name }}</span>
          </div>
        </div>

        <div class="el-col el-col-8">
          <div class="productsdetail_font">还款方式： <span>不限</span></div>
          <div class="productsdetail_font">
            产品类型： <span>{{ product_info.pro_name }}</span>
          </div>
        </div>

        <div class="el-col el-col-24">
          <div class="productsdetail_font " :title="product_info.comment">
            产品信息：{{ product_info.comment ? product_info.comment : '无' }}
          </div>
        </div>
        <div class="el-col el-col-24">
          <div class="productsdetail_font" :title="product_info.remark">
            备注：{{ product_info.remark ? product_info.remark : '无' }}
          </div>
        </div>
      </section>

      <section class="productsdetail_about">
        <div class="productsdetail_about_title">
          <div>机构其他产品</div>
          <div
            style="cursor: pointer;"
            @click="toAgencydetails(product_info.finance_id)"
          >
            查看更多>>
          </div>
        </div>

        <div class="productsdetail_about_list">
          <div
            class="productsdetail_about_item"
            v-for="item in product_other"
            :key="item.id"
            @click="toproductsdetail(item.id)"
          >
            <div class="productsdetail_about_font" :title="item.name">
              {{ item.name }}
            </div>
            <div class="productsdetail_about_font">
              融资额度：{{ item.min_quota }}~{{ item.max_quota }}万
            </div>
            <div class="productsdetail_about_font">
              贷款利率：{{ item.min_rate }}%~{{ item.max_rate }}%
            </div>
            <div class="productsdetail_about_font">
              申请次数：{{ item.req_num }}次
            </div>
          </div>

          <el-empty
            description="暂无数据"
            v-if="product_other.length == 0"
            style="width: 100%;"
          ></el-empty>
        </div>
      </section>

      <section class="productsdetail_recommended">
        <div class="productsdetail_recommended_title">
          <div>推荐产品</div>
          <div style="cursor: pointer;" @click="toFinancialproducts">
            查看更多>>
          </div>
        </div>

        <div
          class="productsdetail_list_good"
          v-for="(item, index) in recommendedList"
          :key="index"
        >
          <div class="recommended_pic_box">
            <img :src="item.gallery" class="recommended_pic" />
          </div>

          <div class="productsdetail_good_rig">
            <div class="productsdetail_good_top">
              <div class="productsdetail_good_name" :title="item.name">
                {{ item.name
                }}<span v-if="item.is_policy == 1">政策型产品</span>
              </div>

              <div
                class="productsdetail_good_detail"
                @click="toproductsdetail(item.id)"
              >
                查看详情
              </div>
            </div>

            <div class="productsdetail_good_bm">
              <div class="productsdetail_good_message">
                <div class="productsdetail_good_label">
                  融资额度：
                  <span>{{ item.min_quota }}~{{ item.max_quota }}万</span>
                </div>
                <div class="productsdetail_good_label">
                  利率：<span>{{ item.min_rate }}%~{{ item.max_rate }}%</span>
                </div>
                <div class="productsdetail_good_label">
                  适用地区： <span>{{ item.area_name }}</span>
                </div>
                <!-- <div class="productsdetail_good_label">抵款期限： <span>{{item.min_credit}}~{{item.max_credit}}月</span></div> -->
                <div class="productsdetail_good_label">
                  担保： <span>{{ item.cate_name }}</span>
                </div>
              </div>

              <div
                class="productsdetail_good_apply"
                @click="towebapply(item.id)"
              >
                立即申请
              </div>
            </div>
          </div>
        </div>

        <el-empty
          description="暂无数据"
          v-if="recommendedList.length == 0"
          style="width: 100%;"
        ></el-empty>
      </section>
    </div>
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb';

export default {
  data() {
    return {
      info_font: '',
      info_flag: -1,
      product_info: {},
      product_other: [],
      recommendedList: [],
      adopt: -1,
      nowTime: '',
      lastTime: '',
      ids: this.$route.query.ids,
    };
  },
  components: {
    'app-mybreadcrumb': MyBreadcrumb,
  },
  methods: {
    toAgencydetails(id) {
      this.$router.push({ path: '/agencydetails', query: { ids: id } });
    },
    toFinancialproducts() {
      this.$router.push('/financialproducts');
    },
    toproductsdetail(id) {
      if (id == this.ids) {
        this.$router.go(0);
      } else {
        this.$router.push({
          name: 'ProductsDetailLink',
          query: { ids: id, type: 2 },
        });
        this.ids = id;
        this.getData();
      }
      // this.$router.push({path: '/productsdetail', query: {ids: id, type: 2}})

      // this.$router.go(0)
    },
    // 用户信息
    getVali(item) {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push({
                path: '/webproductapply',
                query: { ids: item, type: this.$route.query.type },
              });
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            this.$message({
              message: '金融机构不能申请产品',
              type: 'error',
            });
          }
        }
      });
    },
    towebapply(item) {
      this.nowTime = new Date();

      if (this.nowTime - this.lastTime > 1500) {
        if (this.info_flag == 1) {
          this.getVali(item);
        } else {
          this.$message({
            message: '产品已申请！',
            type: 'error',
          });
        }

        this.lastTime = this.nowTime;
      }
    },
    // 产品详情
    getData() {
      let data = { id: this.ids };

      this.$post('product_info', data).then((res) => {
        if (res.data.status == 1) {
          this.product_info = res.data.result.detail;
          this.product_other = res.data.result.other;
          this.recommendedList = res.data.result.pro_refe
            ? res.data.result.pro_refe
            : [];

          this.getVailAsk();
        }
      });
    },
    // 是否申请
    getVailAsk() {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : '',
        product_id: this.product_info.id,
      };
      if (!data.token) {
        this.$$jump(location.href);
      }
      this.$post('/vail_ask', data).then((res) => {
        if (res.data.status == 1) {
          this.info_font =
            res.data.result == 0 || res.data.result == 3 || res.data.result == 4
              ? '立即申请'
              : '已申请';
          this.info_flag =
            res.data.result == 0 || res.data.result == 3 || res.data.result == 4
              ? '1'
              : '2';
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
@import './productsdetail.css';
</style>
